<template>
  <div class="content">
    <a-page-header style="padding: 0" title="Milestone completion" />

    <span style="margin-right: 10px">
      Current Project
      <a-dropdown :trigger="['click']">
        <template #overlay>
          <a-menu>
            <a-menu-item
              v-for="item in pojectList"
              :key="item.id"
              @click="current = item"
            >
              {{ item.name }}({{ item.id }})
            </a-menu-item>
          </a-menu>
        </template>
        <a-button style="min-width: 100px">
          {{ current.name }}
          <DownOutlined />
        </a-button>
      </a-dropdown>
    </span>

    <a-radio-group
      v-model:value="value1"
      button-style="solid"
      style="margin-left: 20px"
    >
      <a-radio-button style="height: 34px" value="1">
        <TableOutlined /> Table</a-radio-button
      >
      <a-radio-button style="height: 34px" value="2"
        ><BarChartOutlined /> Chart</a-radio-button
      >
    </a-radio-group>

    <div class="mt-30" v-if="list">
      <MilestoneTable :data="list" :loading="listloading" v-if="value1 == 1" />
      <MilestoneChart :data="list" v-else />
    </div>
  </div>
</template>

<script>
import { useQuery, useResult } from "@vue/apollo-composable";

import {
  DownOutlined,
  BarChartOutlined,
  TableOutlined,
} from "@ant-design/icons-vue";
import { reactive, toRefs, ref, inject } from "vue";
import MilestoneTable from "@/components/table/MilestoneTable";
import MilestoneChart from "@/components/chart/MilestoneChart";
import Datepicker from "@/components/DatePick";
export default {
  components: {
    MilestoneChart,
    MilestoneTable,
    DownOutlined,
    Datepicker,
    TableOutlined,
    BarChartOutlined,
  },

  setup() {
    const { $msg, gql } = inject("$");

    const v = reactive({
      value1: "1",
      dimension: "year",
      start: "Y2019",
      end: "Y" + new Date().getFullYear(),
      list: [],
    });

    const QL = gql`
      query {
        projects {
          id
          name
        }
      }
    `;

    const {
      result: projectRes,
      onResult: onProjectRes,
      loading: pojectListloading,
      onError: onProjectErr,
    } = useQuery(QL, null, { fetchPolicy: "network-only" });

    const pojectList = useResult(projectRes, null, (data) => data.projects);

    const current = ref({});

    const enabled = ref(false);

    onProjectRes((res) => {
      if (!res.data) return;
      current.value = res.data.projects[0] ? res.data.projects[0] : {};
      if (res.data.projects[0]) {
        enabled.value = true;
      } else {
        $msg.warning("Have no project");
      }
    });

    onProjectErr((error) => {
      $msg.error("Failed to fetch projects");
    });

    const QUERY_ISSUES = gql`
      query ($projectId: Int) {
        milestoneByProjectId(projectId: $projectId) {
          id
          projectId
          title
          state
          startDate: dueDate
          dueFirstDate
          dueUpdateTime
          timeDimensionStart
          timeDimensionEnd
        }
      }
    `;

    const {
      result,
      onResult,
      loading: listloading,
      onError,
    } = useQuery(
      QUERY_ISSUES,
      () => ({
        projectId: current.value.id,
      }),
      { fetchPolicy: "network-only" }
    );

    // const list = useResult(result, [], (data) => data.milestoneByProjectId);

    onResult((queryResult) => {
      if (!queryResult.data) return;
      v.list = JSON.parse(JSON.stringify(queryResult.data.milestoneByProjectId));
      getIssueInfo();
    });
    onError((error) => {
      $msg.error("Failed to fetch data");
    });

    const QUERY_ISSUES_INFO = gql`
      query ($milestoneId: Int) {
        milestoneStatistic(milestoneId: $milestoneId) {
          milestoneId
          onTimeCount
          advanceCount
          advanceTime
          overdueCount
          overdueTime
          postponeCount
          postponeNum
          postponeTime
          complete
        }
      }
    `;

    const {
      onResult: onIssuesInfoRes,

      refetch,
    } = useQuery(
      QUERY_ISSUES_INFO,
      () => ({
        milestoneId: null,
      }),
      { fetchPolicy: "network-only" }
    );

    onIssuesInfoRes((queryResult) => {});

    function getIssueInfo(id) {
      v.list.forEach(async (e, index) => {
        const res = await refetch({ milestoneId: e.id });
        v.list[index] = {
          ...res.data.milestoneStatistic,
          ...e,
        };
      });
    }

    return {
      ...toRefs(v),
      listloading,
      pojectList,
      pojectListloading,
      current,
    };
  },
};
</script>
<style lang="scss" scoped></style>
