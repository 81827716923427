<template>
  <a-table
    :dataSource="data"
    :columns="columns"
    :loading="loading"
    :pagination="{
      hideOnSinglePage: true,
      total: total,
      current: currentPage,
      defaultPageSize: limit,
    }"
    rowKey="title"
    class="ant-table-striped"
    :rowClassName="
      (record, index) => (record.status == 'LOCKED' ? 'table-striped' : null)
    "
  >
    <template #name="{ text }"> {{ text }} </template>
    <template #username="{ text }"> {{ text }} </template>
    <template #duration="{ record, text }">
      {{
        text
          ? `${$filters.formatTime(text, "YYYY/MM/DD")}-${$filters.formatTime(
              record.dueDate,
              "YYYY/MM/DD"
            )}`
          : ""
      }}
    </template>

    <template #complete="{ text }">
      <a-progress
        :percent="Number(text.toFixed(2))"
        size="small"
        v-if="text != undefined"
      />
    </template>

    <!-- <template #tool="{ text }">
        <a-dropdown :trigger="['click']">
          <a class="ant-dropdown-link" @click.prevent>
            <SettingOutlined />
          </a>
          <template #overlay>
            <a-menu>
              <a-menu-item>
                <a
                  href="javascript:;"
                  @click="
                    showStatusModal = true;
                    upateStatusI = text;
                    newStatus = list[text].status;
                  "
                  >Status</a
                >
              </a-menu-item>
            
             
              <a-menu-item>
                <a
                  href="javascript:;"
                  @click.stop.prevent="showDeleteConfirm(text)"
                  >Delete</a
                >
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </template> -->
  </a-table>
</template>

<script>
// @ is an alias to /src

import { reactive, toRefs } from "@vue/reactivity";

import { SettingOutlined } from "@ant-design/icons-vue";
import { inject, watch } from "@vue/runtime-core";
import { useQuery, useResult } from "@vue/apollo-composable";

const columns = [
  {
    title: "Title",
    dataIndex: "title",
  },
  {
    title: "OnTime Count",
    dataIndex: "onTimeCount",
  },
  {
    title: "AdvanceCount",
    dataIndex: "advanceCount",
    slots: { customRender: "dueDate" },
  },
  {
    title: "AdvanceTime(day)",
    dataIndex: "advanceTime",
    slots: { customRender: "lastLoginTime" },
  },
  {
    title: "Overdue Count",
    dataIndex: "overdueCount",
    slots: { customRender: "lastLoginTime" },
  },
  {
    title: "Overdue Time(day)",
    dataIndex: "overdueTime",
    slots: { customRender: "lastLoginTime" },
  },
  {
    title: "postpone Countt",
    dataIndex: "postponeCount",
    slots: { customRender: "lastLoginTime" },
  },
  {
    title: "postpone Num",
    dataIndex: "postponeNum",
    slots: { customRender: "lastLoginTime" },
  },
  {
    title: "postpone Time(day)",
    dataIndex: "postponeTime",
    slots: { customRender: "lastLoginTime" },
  },
  {
    title: "Duration",
    dataIndex: "startDate",
    slots: { customRender: "duration" },
  },
  {
    title: "Complete",
    dataIndex: "complete",
    slots: { customRender: "complete" },
  },

  // {
  //   title: "...",
  //   key: "tool",
  //   dataIndex: "key",
  //   slots: { customRender: "tool" },
  // },
];
export default {
  components: {
    SettingOutlined,
  },

  props: {
    data: { type: Array },
    loading: { type: Boolean },
  },
  setup(props, { emit }) {
    const { $msg, gql } = inject("$");

    const v = reactive({
      limit: 20,
      total: 0,
      currentPage: 1,
      page: 1,
    });

    function tableChange(index) {
      v.page = index.current;
      v.currentPage = index.current;
    }

    const QUERY_ISSUES_INFO = gql`
      query ($milestoneId: Int) {
        milestoneStatistic(milestoneId: $milestoneId) {
          milestoneId
          onTimeCount
          advanceCount
          advanceTime
          overdueCount
          overdueTime
          postponeCount
          postponeNum
          postponeTime
        }
      }
    `;

    const { result, onResult, onError, refetch } = useQuery(
      QUERY_ISSUES_INFO,
      () => ({
        milestoneId: null,
      }),
      { fetchPolicy: "network-only" }
    );

    onResult((queryResult) => {
      if (queryResult.data) {
        let index = props.data.findIndex(
          (e) => e.id == queryResult.data.milestoneStatistic.milestoneId
        );
        if (index != null) {
          let obj = {
            ...queryResult.data.milestoneStatistic,
            ...props.data[index],
          };
          emit("update", index, obj);
        }
      }
    });

    watch(
      () => props.data,
      () => {
        props.data.forEach((e) => {
          refetch({ milestoneId: e.id });
        });
      }
    );

    return {
      ...toRefs(v),
      columns,
      tableChange,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/style/mixins";

.content {
  background-color: $color-white;
  padding: 15px;
}
</style>
