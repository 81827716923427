<template>
  <a-row type="flex" justify="start" class="">
    <div class="me-10">
      Dimension:
      <a-dropdown :trigger="['click']">
        <template #overlay>
          <a-menu>
            <a-menu-item
              v-for="(item, index) in dimensionList"
              :key="index"
              @click="dimension = item"
            >
              {{ item }}
            </a-menu-item>
          </a-menu>
        </template>
        <a-button class="dropdown" style="width: 120px">
          {{ dimension }}
          <DownOutlined />
        </a-button>
      </a-dropdown>
    </div>
    <a-row
      class="me-10"
      v-if="
        dimension == 'quarter' || dimension == 'week' || dimension == 'hour'
      "
    >
      <a-space class="me-10">
        <span class="" style="margin-left: 10px">From:</span>
        <Datepicker
          v-model="fromTime"
          class="btn-datepick"
          :upperLimit="toTime"
          :lowerLimit="new Date('2019/01/01')"
          :startingView="startingView"
          :minimumView="startingView"
          :inputFormat="timeformat"
        />

        <span style="text-transform: capitalize">{{ dimension }}:</span>
        <input
          type="number"
          class="btn-datepick"
          style="width: 80px"
          v-model="formNumber"
          :max="dimension == 'hour' ? 24 : 52"
          :min="dimension == 'hour' ? 0 : 1"
          v-if="dimension == 'week' || dimension == 'hour'"
        />
        <a-dropdown :trigger="['click']" v-else>
          <template #overlay>
            <a-menu>
              <a-menu-item
                @click="formNumber = item"
                :key="item"
                v-for="item in 4"
              >
                Q{{ item }}
              </a-menu-item>
            </a-menu>
          </template>
          <a-button class="dropdown">
            Q{{ formNumber }} <DownOutlined
          /></a-button>
        </a-dropdown>
      </a-space>
      <a-space>
        <span style="margin-left: 10px">To:</span>
        <Datepicker
          v-model="toTime"
          class="btn-datepick"
          :lowerLimit="fromTime"
          :startingView="startingView"
          :minimumView="startingView"
          :inputFormat="timeformat"
        />
        <span style="text-transform: capitalize">{{ dimension }}:</span>
        <input
          type="number"
          class="text-start btn btn-datepick"
          style="width: 80px"
          v-model="toNumber"
          :max="dimension == 'hour' ? 24 : 52"
          :min="dimension == 'hour' ? 0 : 1"
          v-if="dimension == 'week' || dimension == 'hour'"
        />
        <a-dropdown :trigger="['click']" v-else>
          <template #overlay>
            <a-menu>
              <a-menu-item
                @click="toNumber = item"
                :key="item"
                v-for="item in 4"
              >
                Q{{ item }}
              </a-menu-item>
            </a-menu>
          </template>
          <a-button class="dropdown">
            Q{{ toNumber }} <DownOutlined
          /></a-button>
        </a-dropdown>
      </a-space>
    </a-row>
    <a-row type="flex" justify="start" v-else>
      <a-space class="me-10">
        <span style="margin-left: 10px">From</span>
        <Datepicker
          v-model="fromTime"
          class="btn-datepick"
          :upperLimit="toTime"
          :lowerLimit="new Date('2019/01/01')"
          :startingView="startingView"
          :minimumView="startingView"
          :inputFormat="timeformat"
        />
      </a-space>
      <a-space class="dropdown d-flex align-items-center py-1">
        <span style="margin-left: 10px">To</span>
        <Datepicker
          v-model="toTime"
          class="btn-datepick"
          :lowerLimit="fromTime"
          :startingView="startingView"
          :minimumView="startingView"
          :inputFormat="timeformat"
        />
      </a-space>
    </a-row>
  </a-row>
</template>
<script>
import { reactive, toRefs, inject, watch, computed, onMounted } from "vue";
import Datepicker from "vue3-datepicker";
import { DownOutlined } from "@ant-design/icons-vue";

import * as dayjs from "dayjs";
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var weekOfYear = require("dayjs/plugin/weekOfYear");
var isSameOrBefore = require("dayjs/plugin/isSameOrBefore");
dayjs.extend(weekOfYear);
dayjs.extend(quarterOfYear);
dayjs.extend(isSameOrBefore);
export default {
  emits: ["update"],
  components: {
    Datepicker,
    DownOutlined,
  },
  setup(props, { emit }) {
    const v = reactive({
      dimension: "year",
      fromTime: dayjs().subtract(3, "Year").toDate(),
      toTime: new Date(),
      formNumber: 1,
      toNumber: 4,
      start: null,
      end: null,
    });

    v.start = formatTime(dayjs().subtract(3, "Year").toDate(), 1);
    v.end = formatTime(new Date(), 4);

    watch(() => v.fromTime, updateData);
    watch(() => v.formNumber, updateData);
    watch(() => v.toTime, updateData);
    watch(() => v.toNumber, updateData);
    watch(
      () => v.dimension,
      (val) => {
        switch (val) {
          case "year":
            v.limit = 10;
            break;
          case "quarter":
            v.limit = 20;
            if (v.toNumber > 4) v.toNumber = 4;
            break;
          case "month":
            v.limit = 24;
            break;
          case "week":
            v.limit = 53;
            break;

          default:
            break;
        }
        updateData();
      }
    );

    function formatTime(time, number) {
      let timeFactor = `Y${dayjs(time).$y}`;
      let month;
      let day;
      switch (v.dimension) {
        case "quarter":
          timeFactor += `Q${number}`;
          break;
        case "week":
          if (number.length != 2) number = `0${number}`;
          timeFactor += `W${number}`;
          break;
        case "month":
          month = dayjs(time).$M + 1 + "";
          if (month.length != 2) month = `0${month}`;
          timeFactor = `${timeFactor}M${month}`;
          break;
        case "Milestone":
          month = dayjs(time).$M + 1 + "";
          if (month.length != 2) month = `0${month}`;
          timeFactor = `${timeFactor}M${month}`;
          day = dayjs(time).$D + "";
          if (day.length != 2) day = `0${day}`;
          timeFactor = `${timeFactor}D${day}`;
          break;
      }
      return timeFactor;
    }
    async function updateData(params) {
      if (
        v.formNumber > v.toNumber ||
        !dayjs(v.fromTime).isSameOrBefore(dayjs(v.toTime))
      ) {
        return $msg.warning("From time cannot be later than to time");
      }
      let fromTime = formatTime(v.fromTime, v.formNumber);
      let toTime = formatTime(v.toTime, v.toNumber);

      emit("update", {
        dimension: v.dimension,
        start: fromTime,
        end: toTime,
      });
    }

    const startingView = computed(() => {
      switch (v.dimension) {
        case "year":
        case "quarter":
        case "week":
          return "year";
        case "month":
          return "month";
        default:
          return "day";
      }
    });

    const timeformat = computed(() => {
      switch (v.dimension) {
        case "year":
        case "quarter":
        case "week":
          return "yyyy";
        case "month":
          return "yyyy-MM";
        case "Milestone":
          return "yyyy-MM-dd";
        default:
          return "yyyy-MM-dd";
      }
    });

    onMounted(() => {
      // updateData();
    });
    return {
      ...toRefs(v),
      startingView,
      timeformat,
      dimensionList: ["year", "quarter", "month", "week", "Milestone"],
    };
  },
};
</script>
<style lang="scss" scoped></style>
